export const useApi = () => {
  async function ask(prompt: string, history: Context[]) {
    // Disable sending history for now
    history = [];
    return await $larafetch<string>("/api/v1/chat/ask", {
      method: "post",
      body: {
        prompt,
        history,
      },
    });
  }

  async function fetchCategories() {
    return await $larafetch("/api/v1/categories", {
      method: "get",
    });
  }

  async function fetchSuggestions(category_id: number, limit: number) {
    let url: string = "/api/v1/suggestions/";
    url = addUrlParameter(url, "category", category_id);
    url = addUrlParameter(url, "limit", limit);

    return await $larafetch(url, {
      method: "get",
    });
  }

  function addUrlParameter(url: string, key: string, value: any): string {
    if (value !== 0 && !value) return url;
    return url.indexOf("?") === -1
      ? `${url}?${key}=${value}`
      : `${url}&${key}=${value}`;
  }

  return {
    ask,
    fetchCategories,
    fetchSuggestions,
  };
};
