export type Category = {
  id: number;
  slug: string;
  title: string;
  description: string;
  query: string;
  icon_path: string;
  wallpaper_path: string;
  icon_url: string;
  wallpaper_url: string;
};

const { assetsBaseUrl } = useRuntimeConfig().public;

export const useCategoriesStore = defineStore("categories", () => {
  const { fetchCategories } = useApi();

  const categories = ref<Category[]>([]);

  const activeCategoryId = ref();

  const activeCategory = computed(() =>
    getCategoryById(activeCategoryId.value),
  );

  async function fetch() {
    categories.value = (await fetchCategories()) as Category[];
    // Supplement category data
    categories.value.forEach((category) => {
      if (category.icon_path) {
        category.icon_url = assetsBaseUrl + category.icon_path;
      }
      if (category.wallpaper_path) {
        category.wallpaper_url = assetsBaseUrl + category.wallpaper_path;
      }
    });
    // Preload images to avoid flicker when displaying;
    preloadImages();
  }

  function getCategoryById(id: number): Category | undefined {
    return categories.value.find((category: Category) => category.id === id);
  }

  function preloadImages() {
    const images = [];
    categories.value.forEach((category) => {
      // Preload icon
      const icon = new Image();
      icon.src = category.icon_url;
      images.push(icon);
      // Preload wallpaper
      const wallpaper = new Image();
      wallpaper.src = category.wallpaper_url;
      images.push(wallpaper);
    });
  }

  return {
    categories,
    activeCategoryId,
    activeCategory,
    fetch,
    getCategoryById,
  };
});
